.error-form-info {
  display: none;
}
.error-form {
  .error-form-info {
    padding: 5px;
    display: flex;
    align-items: center;
  }
}

.fullscreen-enabled iframe {
  background-color: white;
}

// #scrollbar{
//   max-height: calc(100vh - 5rem);
//   overflow-y: auto;
//   overflow-x: hidden;
// }
.auth-label {
  display: flex;
  justify-content: center;
}
.auth-label:before {
  display: flex;
  text-align: center;
  width: 20px;
  height: 2px;
  margin: auto 1em;
  background: #D4D8DD;
  content: "";
}
.auth-label:after {
  display: flex;
  text-align: center;
  width: 20px;
  height: 2px;
  margin: auto 1em;
  background: #D4D8DD;
  content: "";
}
.btn-input {
	position: relative;
}
.btn-input input {
	position: absolute;
  opacity: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  cursor: pointer;
  left: 0
}
.mandatory {
	color: #E10008;
}
thead tr{
  text-transform: uppercase;
}

//tiny editor 
.tox-editor-container{
  z-index: 0;
}

.rotate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.leaflet-control-container .leaflet-routing-container-hide {
  display: none;
}

.separator {
  flex-grow: 1;
  height: 1px;
  background-color: #ccc;
  align-self: center;
  margin-left: 30px;
  margin-right: 30px;
}
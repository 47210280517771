//
// _payment.scss
//

.payment-section {
  min-height: 100vh !important;
  display: flex;
  align-items: stretch;

  .payment-section-left {
    background-color: #121926 !important;
    height: 100%;
    padding: 120px 56px;
    display: flex;
    justify-content: end;

    .payment-info-section {
      width: 100%;
      max-width: 380px;

      .payment-info-card {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
        background-color: white;
        min-height: 300px;
        margin-top: 25px;
        border-radius: 2px;
        border: 1px solid #d1d1d8;
        padding: 32px;
      }
    }
  }
  .payment-section-right {
    height: 100%;
    padding: 120px 56px;
    display: flex;
    justify-content: start;

    .icon {
      color: #e58e1d;
      font-size: 24px;
    }

    .payment-method-section {
      width: 100%;
      //max-width: 425px;
      height: 100%;

      .payment-count-down {
        display: flex;
        align-items: center;
        gap: 12px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
        border-radius: 5px;
        background-color: #fff3e5;
        padding: 16px;
        //max-width: 425px;
        margin-bottom: 16px;

        .timer {
          display: flex;
          flex-grow: 1;
          justify-content: end;
          font-size: 24px;
          color: #ef2f41;
          font-weight: 600;
          gap: 5px;

          .timer-item {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #ef2f41;
            color: white;
            border-radius: 5px;
            font-size: 16px;
            padding: 0px 4px;
            min-width: 30px;
          }
        }
      }
      .payment-method-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .payment-method-item {
          width: 100%;
          padding: 15px 20px;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
          height: 50px;
          border-radius: 5px;
        }
      }

      .payment-method-detail {
        .payment-method-detail-item {
          border-bottom: 1px solid #d1d1d8;
          border-left: 1px solid #d1d1d8;
          border-right: 1px solid #d1d1d8;
          background-color: white;
          min-height: 60px;
          padding: 6px 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 4px;

          .title {
            color: #b5bdc5;
            font-weight: 600;
            font-size: 13px;
          }

          .content {
            color: #17183b;
            font-weight: 600;
            font-size: 14px;
          }
        }
      }
      .payment-method-detail > :first-child {
        border-top: 1px solid #d1d1d8;
      }

      .accordion-item {
        border: 1px solid #d1d1d8 !important;
      }

      .button-pay-filled {
        width: 100%;
        height: 54px;
        background-color: #e58e1d;
        border-radius: 4px;
        font-weight: 600;
        font-size: 15px;
        color: white;
        margin-top: 32px;
        cursor: pointer;
      }

      .button-pay-outlined {
        width: 100%;
        height: 54px;
        border: 1px solid #e58e1d;
        font-weight: 600;
        font-size: 16px;
        color: #e58e1d;
        margin-top: 32px;
        cursor: pointer;
      }
    }
  }
}
